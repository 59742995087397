import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Page from '../components/Page';
import RightSidebar from '../components/RightSidebar';
import Sidebar from '../components/Sidebar';

const PageTemplate = ({ data }) => {
  const {
    title: siteTitle,
    subtitle: siteSubtitle,
    logo: logo,
  } = data.site.siteMetadata;

  const { title: pageTitle, description: pageDescription } =
    data.markdownRemark.frontmatter;

  const { html: pageBody } = data.markdownRemark;

  const metaDescription =
    pageDescription !== null ? pageDescription : siteSubtitle;

  return (
    <Layout title={`${pageTitle} - ${siteTitle}`} description={metaDescription}>
      <Sidebar />
      <Page logo={logo} title={pageTitle}>
        <div dangerouslySetInnerHTML={{ __html: pageBody }} />
      </Page>
      <RightSidebar />
    </Layout>
  );
};

export const query = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
        logo {
          title
          subtitle
          path
          width
          height
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        created_at
        modified_at
        description
      }
    }
  }
`;

export default PageTemplate;
